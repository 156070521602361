(function () {
    angular.module('informaApp')
        .component('usageSection', {
            templateUrl: 'components/admin-sections/usage-section/template.html',
            controller: UsageSectionController
        });

    function UsageSectionController(AdminUserService, ModalHelper, UserActivityService, ToastrHelper, CvsHelper) {
        loadCompanies.call(this, AdminUserService);

        this.onFilterModalSubmit = (filter) => {
            this.filter = filter;
            this.loading = true;

            UserActivityService.getAll(filter)
                .then(activities => {
                    this.loading = false;
                    this.activities = activities;
                })
                .catch(() => {
                    this.loading = false;
                    ToastrHelper.showError('Something went wrong');
                });
        };

        this.onModalInitialize = (modalElement) => {
            this.modalElement = modalElement;
        };

        this.showModal = () => {
            ModalHelper.showModalByElement(this.modalElement);
        };

        this.exportTable = (headers) => exportDateToCvs(this.filter, this.activities, headers, CvsHelper);
    }

    function loadCompanies(AdminUserService) {
        AdminUserService.getCompanies().then(companies => {
            this.companies = companies;
        });
    }

    function exportDateToCvs(filter, source, headers, CvsHelper) {
        const headerConfig = {source: ['Search Conditions'], emptyLinesAfter: 1};
        const filterConfig = getFilterConfig(filter);
        const tableHeadersConfig = {source: [headers.join(',')], emptyLinesAfter: 2};
        const dataConfig =  {
            source: source,
            getFields: [x => x.userCompany, x => x.userEmail, x => x.userSalesforceId, x => x.activity, x => x.createdAt]
        };

        return CvsHelper.generateAndSave('PremiaUsageReport-' + (+new Date()) + '.csv', [
            headerConfig,
            filterConfig,
            tableHeadersConfig,
            dataConfig
        ]);
    }

    function getFilterConfig(filter) {
        const result = {
            source: [],
            getFields: [x => x.field, x => x.value],
            emptyLinesAfter: 2
        };

        if (filter.company) {
            result.source.push({field: 'Company', value: filter.company});
        }

        if (filter.activityType) {
            result.source.push({field: 'Activity', value: filter.activityType});
        }

        result.source = [
            ...result.source,
            ...getDateRangeConfig(filter)
        ];

        return result;
    }

    function getDateRangeConfig(filter) {
        if (filter.endDate) {
            return [
                {field: 'Time Period:', value: ''},
                {field: 'from', value: formatFilterDate(filter.startDate)},
                {field: 'to', value: formatFilterDate(filter.endDate)}
            ];
        }

        return [{field: 'Date', value: formatFilterDate(filter.startDate)}];
    }

    function formatFilterDate(date) {
        return moment(date).format('MM/DD/YYYY');
    }
})();
